
// import axios from "../Config/Interceptor1";


import axios from '../Configs/Interceptor'
import { addCategoryLoading , addCategorySuccess , addCategoryFailure } from './ActionCreators'
import { getCategoryBegin , getCategorySuccess , getCategoryFailure } from './ActionCreators'
import { editCategoryBegin , editCategorySuccess , editCategoryFailure } from './ActionCreators'
import { delCategoryBegin , delCategorySuccess , delCategoryFailure } from './ActionCreators'


import { Base_URL } from '../Configs/BaseUrl'



export const addCategory = (data) => {
    console.log("-------------------------------")
    
    console.log(data)
    
    console.log("-------------------------------")


    
    return (dispatch) => {
        dispatch(addCategoryLoading());

        axios.put(Base_URL + '/api/v1/admin/addCategory', data)
            .then((res) => {
                console.log(res)
                dispatch(addCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addCategoryFailure(err.response.data));

                }
            })
    }
}




export const getCategory = () => {

    return (dispatch) => {
        dispatch(getCategoryBegin());

        axios.get(Base_URL + '/api/v1/admin/getAllcategories' )
            .then((res) => {
                console.log(res)
                dispatch(getCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")
                }
                else {
                    dispatch(getCategoryFailure(err.response));

                }
            })
    }
}


export const DelCategory = (data) => {
    console.log(data)



    // var fdata = new FormData();
    // fdata.append('id' , data)


    const newD = {
        'id' : data
    }
    // console.log(newD)



    // var consfg = {
    //     headers: {
    //       Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI4OTQ5NTQ3NDE0IiwiaWF0IjoxNjEwODgyOTUzLCJleHAiOjE2MTA4ODY1NTN9.OwVahHvt4gUq9t5SoHo6xfbtHmuJxGMYLHI-HMEvq9c`
    //     },
    //     data: {
    //       'id': data.toString()
    //     }
    //   }



   
    return (dispatch) => {
        dispatch(delCategoryBegin());

        axios.post(Base_URL + '/api/v1/admin/deleteCategory',  newD)
            .then((res) => {
                console.log(res)
                dispatch(delCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delCategoryFailure(err.response.data));

                }
            })
    }
}




export const editCategory = (data) => {


    return (dispatch) => {
        dispatch(editCategoryBegin());
        axios.post(Base_URL + '/api/v1/admin/updateCategories', data)
            .then((res) => {
                console.log(res)
                dispatch(editCategorySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editCategoryFailure(err.response.data));

                }
            })
    }
}













