import {TEST_JOB_LOADING , TEST_JOB_SUCCESS , TEST_JOB_FAILURE  } from './Types' ;

import {ADD_CATEGORY_LOADING , ADD_CATEGORY_SUCCESS , ADD_CATEGORY_FAILURE  } from './Types' ;
import {GET_CATEGORY_LOADING , GET_CATEGORY_SUCCESS , GET_CATEGORY_FAILURE  } from './Types' ;
import {EDIT_CATEGORY_LOADING , EDIT_CATEGORY_SUCCESS , EDIT_CATEGORY_FAILURE  } from './Types' ;
import {DEL_CATEGORY_LOADING , DEL_CATEGORY_SUCCESS , DEL_CATEGORY_FAILURE  } from './Types' ;
import {CLEAN_CATEGORY_DATA  } from './Types' ;




import {UPLOAD_TRACK_IMAGE_LOADING,
  UPLOAD_TRACK_IMAGE_SUCCESS,
  UPLOAD_TRACK_IMAGE_FAILURE,
  CLEAN_TRACK_IMAGE_DATA,
  UPLOAD_TRACK_AUDIO_LOADING,
  UPLOAD_TRACK_AUDIO_SUCCESS,
  UPLOAD_TRACK_AUDIO_FAILURE,
  CLEAN_TRACK_AUDIO_DATA} from './Types'
import {ADD_UPLOAD_TRACK_LOADING , ADD_UPLOAD_TRACK_SUCCESS , ADD_UPLOAD_TRACK_FAILURE  } from './Types' ;
import {GET_UPLOAD_TRACK_LOADING , GET_UPLOAD_TRACK_SUCCESS , GET_UPLOAD_TRACK_FAILURE  } from './Types' ;
import {EDIT_UPLOAD_TRACK_LOADING , EDIT_UPLOAD_TRACK_SUCCESS , EDIT_UPLOAD_TRACK_FAILURE  } from './Types' ;
import {DEL_UPLOAD_TRACK_LOADING , DEL_UPLOAD_TRACK_SUCCESS , DEL_UPLOAD_TRACK_FAILURE  } from './Types' ;
import {CLEAN_UPLOAD_TRACK_DATA  } from './Types' ;


import {REFRESH_TOKEN_LOADING , REFRESH_TOKEN_SUCCESS , REFRESH_TOKEN_FAILURE  } from './Types' ;

import {USER_LOGIN_LOADING , USER_LOGIN_SUCCESS , USER_LOGIN_FAILURE } from './Types' ;

import {GET_DASH_LOADING , GET_DASH_SUCCESS , GET_DASH_FAILURE } from './Types' ;

import {LOGOUT_ADMIN } from './Types' ;



// Test Reducer
export const testDataLoading = () => ({
    type : TEST_JOB_LOADING,
  })
  
export const testDataSuccess = (data) => ({
    type : TEST_JOB_SUCCESS,
    data
  })
  
export const testDataFailure = (error) => ({
    type : TEST_JOB_FAILURE,
    error
  })

  /**************************************  Login      ********************************************************* */

  export const userLoginBegin = () => ({
    type : USER_LOGIN_LOADING
})

export const userLoginSuccess = (data) => ({
    type : USER_LOGIN_SUCCESS,
    data ,
})

export const userLoginFailure = (error) => ({
    type : USER_LOGIN_FAILURE,
    error
    
})


/**************************************  Refresh token      ********************************************************* */



  
  export const refreshTokenBegin = () => ({
    type : REFRESH_TOKEN_LOADING
  })
  
  export const  refreshTokenSuccess = (token) => ({
  type : REFRESH_TOKEN_SUCCESS,
  token,
  })
  
  export const refreshTokenFailure = (error) => ({
  type :REFRESH_TOKEN_FAILURE,
  error
  })
  


export const getDashboardBegin = () => ({
  type : GET_DASH_LOADING
})

export const getDashboardSuccess = (getdata) => ({
  type : GET_DASH_SUCCESS,
  getdata ,
})

export const getDashboardFailure = (geterror) => ({
  type : GET_DASH_FAILURE,
  geterror
  
})


/**************************************  Category   ********************************************************* */




   // Add Category
  
 export const addCategoryLoading = () => ({
  type : ADD_CATEGORY_LOADING,
})

export const addCategorySuccess = (data) => ({
  type : ADD_CATEGORY_SUCCESS,
  data
})

export const addCategoryFailure = (error) => ({
  type : ADD_CATEGORY_FAILURE,
  error
})


// Get Category

export const getCategoryBegin = () => ({
  type : GET_CATEGORY_LOADING
})

export const getCategorySuccess = (getdata) => ({
  type : GET_CATEGORY_SUCCESS,
  getdata ,
})

export const getCategoryFailure = (geterror) => ({
  type : GET_CATEGORY_FAILURE,
  geterror
  
})



export const editCategoryBegin = () => ({
  type : EDIT_CATEGORY_LOADING
})

export const editCategorySuccess = (editdata) => ({
  type : EDIT_CATEGORY_SUCCESS,
  editdata ,
})

export const editCategoryFailure = (editerror) => ({
  type : EDIT_CATEGORY_FAILURE,
  editerror
  
})



export const  delCategoryBegin = () => ({
  type : DEL_CATEGORY_LOADING
})

export const  delCategorySuccess = (deldata) => ({
  type : DEL_CATEGORY_SUCCESS,
  deldata ,
})

export const  delCategoryFailure = (delerror) => ({
  type : DEL_CATEGORY_FAILURE,
  delerror
  
})

 
// Clean Category Data

export const cleanCategoryData = () => ({
  type : CLEAN_CATEGORY_DATA,
  
})




  /************************************** Upload Track   ********************************************************* */




  
   export const UploadTrackImageLoading = () => ({
    type : UPLOAD_TRACK_IMAGE_LOADING,
  })
  
  export const UploadTrackImageSuccess = (data) => ({
    type : UPLOAD_TRACK_IMAGE_SUCCESS,
    data
  })
  
  export const UploadTrackImageFailure = (error) => ({
    type : UPLOAD_TRACK_IMAGE_FAILURE,
    error
  })
  
  

  export const cleanTrackImageData = () => ({
    type : CLEAN_TRACK_IMAGE_DATA,
    
  })



  
   export const UploadTrackAudioLoading = () => ({
    type : UPLOAD_TRACK_AUDIO_LOADING,
  })
  
  export const UploadTrackAudioSuccess = (data) => ({
    type : UPLOAD_TRACK_AUDIO_SUCCESS,
    data
  })
  
  export const UploadTrackAudioFailure = (error) => ({
    type : UPLOAD_TRACK_AUDIO_FAILURE,
    error
  })
  
  
  export const cleanTrackAudioData = () => ({
    type : CLEAN_TRACK_AUDIO_DATA,
    
  })



  
   export const addUploadTrackLoading = () => ({
    type : ADD_UPLOAD_TRACK_LOADING,
  })
  
  export const addUploadTrackSuccess = (data) => ({
    type : ADD_UPLOAD_TRACK_SUCCESS,
    data
  })
  
  export const addUploadTrackFailure = (error) => ({
    type : ADD_UPLOAD_TRACK_FAILURE,
    error
  })
  
  
  
  export const getUploadTrackBegin = () => ({
    type : GET_UPLOAD_TRACK_LOADING
  })
  
  export const getUploadTrackSuccess = (getdata) => ({
    type : GET_UPLOAD_TRACK_SUCCESS,
    getdata ,
  })
  
  export const getUploadTrackFailure = (geterror) => ({
    type : GET_UPLOAD_TRACK_FAILURE,
    geterror
    
  })


  
  export const editUploadTrackBegin = () => ({
    type : EDIT_UPLOAD_TRACK_LOADING
  })
  
  export const editUploadTrackSuccess = (editdata) => ({
    type : EDIT_UPLOAD_TRACK_SUCCESS,
    editdata ,
  })
  
  export const editUploadTrackFailure = (editerror) => ({
    type : EDIT_UPLOAD_TRACK_FAILURE,
    editerror
    
  })




  export const delUploadTrackBegin = () => ({
    type : DEL_UPLOAD_TRACK_LOADING
  })
  
  export const delUploadTrackSuccess = (deldata) => ({
    type : DEL_UPLOAD_TRACK_SUCCESS,
    deldata ,
  })
  
  export const delUploadTrackFailure = (delerror) => ({
    type : DEL_UPLOAD_TRACK_FAILURE,
    delerror
    
  })


  export const cleanUploadTrackData = () => ({
    type : CLEAN_UPLOAD_TRACK_DATA,
    
  })




export const LogoutAdmin = () => ({
  type : LOGOUT_ADMIN,
  
})
  
  



  




