import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
// import routes from './routes';
import {Provider , connect} from 'react-redux'
import UPS from './Load'



import Route1 from './Routes/Routes1';
import Route2 from './Routes/Routes2';
import store from './Store/Store';

import history from './Configs/history';

const appAuth = false;

const App = () => {
  // const routing = useRoutes(routes);

  store.subscribe(() => {
    localStorage.setItem('reduxStore' , JSON.stringify(store.getState()));
    // console.log(store.getState().login.data.token)
  })

  const Routing1 = useRoutes(Route1);
  const Routing2 = useRoutes(Route2);


  return (
    <Provider store={store} history={history}>
    <ThemeProvider theme={theme}>
      
<UPS/>
      <GlobalStyles />
      {/* {routing} */}

      {!store.getState().Login.data.token ?
      Routing1
      :
      Routing2
      }
    </ThemeProvider>
    </Provider> 
  );
};

export default App;
