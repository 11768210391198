import {USER_LOGIN_FAILURE , USER_LOGIN_SUCCESS , USER_LOGIN_LOADING } from '../Actions/Types';

import {LOGOUT_ADMIN} from '../Actions/Types';




const Login = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},

        }
    }




switch(action.type) {
    

    case USER_LOGIN_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
        case LOGOUT_ADMIN:
        return {
            ...state,
            data: [{}],
            loading:false,
            error:{},
            
        }
    case USER_LOGIN_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case USER_LOGIN_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

 

    default:
        return state






}
}



export default Login;
