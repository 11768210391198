import { combineReducers  } from "redux";


import Category from './Cat';
import UploadTrack from './UploadTrack';
import Auth from './RefreshToken';
import Login from './Login';

import Dashboard from './Dash'




   
export default combineReducers({

    
    Category,
    UploadTrack,
    Auth,
    Login,    
    Dashboard

});
