import React, {useState, useEffect, useRef}from 'react';
import {  useNavigate  } from 'react-router-dom';

import Page from '../../components/Page';
import moment from 'moment';
import { useSelector , useDispatch  } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Configs/Toast'
import {ToastContainer} from 'react-toastify';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem  
} from '@material-ui/core';
import ReactAudioPlayer from 'react-audio-player';


import {UploadTrackAudio, UploadTrackImage, addUploadTrack} from '../../Actions/UploadTrack'
import {cleanUploadTrackData, cleanTrackImageData, cleanTrackAudioData } from '../../Actions/ActionCreators'
import {getCategory} from '../../Actions/cat'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 100,
    width: 100,
    marginRight:"20%"
  },
  formControl: {    
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));


const steps = ['Upload Track Image', 'Add Track', 'Submit Track'];


const AddTrack = ({ className, ...rest }) =>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);  

  
  const [values, setValues] = useState({
    name : "",
    cid:'',

    podcast:"",
    
    audio:"",
    image: "",

    audio1:"",
    tempAudio1:"",

    tempImage1:'',
    fixImg:'/static/music.jpg',
    image1:"",

  })

  const handleNext = () => {    
    if(activeStep===0){
      if(values.tempImage1 === "" || values.tempImage1 === null || values.tempImage1 === undefined){
        tostE("Please Upload Image First")
      }else{
        console.log(values.image1)
        dispatch(UploadTrackImage({'image':values.image1}))
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        tostS("Image Uploaded SuccessFully")
      }
    }
    if(activeStep===1){
      if(values.tempAudio1 === "" || values.tempAudio1 === null || values.tempAudio1 === undefined){
        tostE("Please Upload Audio File First")
      }else{
        console.log(values.audio1)
        dispatch(UploadTrackAudio({'podcast':values.audio1}))
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        tostS("Audio Uploaded SuccessFully")

      }
    }
    if(activeStep===2){
      if(values.name === "" || values.name === null || values.name === undefined){
        tostE("Please Enter Song Name")
      }else if(values.cid === "" || values.cid === null || values.cid === undefined){
        tostE("Please Select Song Category")
      }else{
        const sendData = {
          "name":values.name,
          "cid":values.cid,
          "audio":values.audio,
          "image":values.image
        }
        console.log(sendData)
        dispatch(addUploadTrack(sendData))
        navigate("/app/tracklist")
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const handleChangecat = (x) => {
    setValues({...values, ['cid']:x})
  };



  

  useEffect(()=>{
    dispatch(getCategory())
    
    
  
  },[])




/************ Audio Handling  **************/
const hiddenFileInput2 = useRef(null);

const handleClick2 = event => {
  hiddenFileInput2.current.click();
};

const onFileChange = event => { 
    
  // console.log(event.target.files[0])
  if(event.target.files[0] && event.target.files.length !==0)
  {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded)
    setValues({
      ...values , 
      ['audio1']:fileUploaded , 
      ['tempAudio1']:URL.createObjectURL(fileUploaded),
      ['audio']:fileUploaded.name
    })
  }
  else
  {
    console.log('yahah')
  }
 
}; 



/************ Audio Handling  **************/





  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);

 
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      
      setValues({
        ...values , 
        ['image1']:fileUploaded , 
        ['tempImage1']:URL.createObjectURL(fileUploaded), 
        ['image']:fileUploaded.name
      })
    }
    else
    {
      console.log('yahah')
    }

  };





  
  /********* Image Handling   ************** */



  const CatData = useSelector((state)=>state.Category && 
  state.Category.getdata && state.Category.getdata.results &&
  Array.isArray(state.Category.getdata.results) && state.Category.getdata.results.length?
  state.Category.getdata.results : [])




return(
  <Page className={classes.root} title="Add Track">
    <ToastContainer />
    <Container maxWidth="lg">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};          
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      
      <Card>        
        <CardHeader title={steps[activeStep]}/>

        <CardContent>
          {activeStep===0 ?
            (
              <>
                <Box alignItems="center" display="flex" flexDirection="row" mb={3}>
                  <Avatar className={classes.avatar} src={values.tempImage1 !== '' ?  values.tempImage1 : values.fixImg }/>
                  <Typography color="primary" gutterBottom variant="h4">
                    {values.image!==''?values.image : "No File Selected"}
                  </Typography>
                </Box>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChangeimg}
                  style={{display: 'none'}} 
                />
                <Button
                  color="primary"
                  fullWidth
                  variant="outlined"
                  onClick={handleClick}
                >
                  Upload picture
                </Button>
              </>
            ):
            activeStep===1?
            (
              <>
                <Box display="flex" flexDirection="row" style={{alignItem:'center', justifyContent:"center"}} >
                  {values.tempAudio1 !== "" ?             
                    <ReactAudioPlayer
                      src={values.tempAudio1}
                      autoPlay={false}
                      controls
                    />
                    :
                    // <h3  style={{justifyContent:"center"}}> Audio File Not Uploaded</h3>
                    <ReactAudioPlayer
                      src={values.tempAudio1}
                      autoPlay={false}
                      controls
                    />
                  }    
                  <input
                    type="file"
                    ref={hiddenFileInput2}
                    onChange={onFileChange}
                    style={{display: 'none'}} 
                  />
                  <Button
                    color="primary"
                    fullWidth
                    variant="text"
                    onClick={handleClick2}
                  >
                    Upload Song
                  </Button>
                </Box>
              </>
            ):
            (activeStep===2) &&
            (
              <>
                <Grid container spacing={3}>
                  <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Song Name"
                      name="name"
                      onChange={handleChange}
                      required
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Select Category</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.cid}                      
                      label="Category"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {CatData.map((data , i)=>
                        <MenuItem key={i} onClick={()=>handleChangecat(data.id)} value={data.id}>{data.name}</MenuItem>
                      )}    
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Image"
                      name="image"
                      disabled
                      value={values.image}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Audio"
                      disabled
                      name="audio"
                      value={values.audio}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </>
            )
          }
        </CardContent>
        <Divider />
        
          <Box display="flex" justifyContent="flex-end" p={2}>
              {/* <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 3 }}
              >
                Back
              </Button> */}

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>          
          </Box>
                      
        
      </Card>

      
      
    </Container>
  </Page>
)

}


export default AddTrack;