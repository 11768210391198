import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Configs/Toast";
import clsx from "clsx";
import { connect } from "react-redux";


import {getCategory , DelCategory} from '../../Actions/cat'
import {cleanCategoryData} from '../../Actions/ActionCreators'
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import tableIcons from '../../Configs/IconsFile'


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  
  




const listData = useSelector((state) =>
  state.Category.getdata.results &&
    state.Category.getdata.results.length &&
    Array.isArray(state.Category.getdata.results)
      ? state.Category.getdata.results
      : []
  );




  const editmsg = useSelector(state => state.Category.editdata && state.Category.editdata.status ? state.Category.editdata.status : null  )
  const editerror = useSelector(state => state.Category.editerror && state.Category.editerror.message ? state.Category.editerror.message : null  )

  const addmsg = useSelector(state => state.Category.data && state.Category.data.status ? state.Category.data.status : null  )
  const adderror = useSelector(state => state.Category.error && state.Category.error.message ? state.Category.error.message : null  )




  

  
useEffect( () => {

    dispatch(getCategory())


    let x  = new Promise((resolve ,reject)=>{
      
      if(editmsg && typeof(editmsg) === 'string')
      {
        tostS("Updated Successfully!!")
      }
      if(editerror && typeof(editerror) === 'string')
      {
          tostE(editerror)
      }
      if(addmsg && typeof(addmsg) === 'string')
      {
        tostS("Added Successfully!!")
      }
      if(adderror && typeof(adderror) === 'string')
      {
          tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanCategoryData())
    dispatch(getCategory())

    })


    
  }, [editmsg, editerror, addmsg, adderror])


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/editCat/'+olData.id , { state: olData })
}


const onDel = (oldata) =>{

  dispatch(DelCategory(oldata.id ,oldata.tableData.id ))
}
 







  


  const addArtistButton = () =>{
    navigate('/app/addcategory')
    
  }

  return (
    <>
      <Page className={classes.root} title="Categories">
      <ToastContainer    />
        <Container maxWidth={false}>          
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">              
              <Button onClick={addArtistButton} color="primary" variant="contained">
                Add Category
              </Button>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
              icons={tableIcons}      
              title="Added Categories"
              columns={[
                
                { title: 'ID', field: 'id', editable: "never", },     
                { title: 'Name', field: 'name',editable: "never", },
                
                {
                  title: 'Updated Date', field: "updatedAt",editable: "never",
                  render: rowData => (
                    moment(rowData.updatedAt).format("DD/MM/YYYY")
                  ),
                },
                {
                  title: 'Action',
                  render: rowData => (
                    <Grid container className={classes.root2}>
              <Grid item xs={4}>
                
              <EditIcon  onClick={()=>{onEdit(rowData)}} style={{cursor:"pointer" }} />
              </Grid>
              
              </Grid>
                  ),
                },

              ]}
              data={listData}
              options={{
                      selection: false,
                      textAlign: "center",
                      headerStyle: { textAlign: "left" },
                      rowStyle: { textAlign: "center" }
                    }}

            />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
