
import axios from "../Configs/Interceptor";


// import axios from 'axios'
import { UploadTrackImageLoading , UploadTrackImageSuccess , UploadTrackImageFailure } from './ActionCreators'
import { UploadTrackAudioLoading , UploadTrackAudioSuccess , UploadTrackAudioFailure } from './ActionCreators'

import { addUploadTrackLoading , addUploadTrackSuccess , addUploadTrackFailure } from './ActionCreators'
import { getUploadTrackBegin , getUploadTrackSuccess , getUploadTrackFailure } from './ActionCreators'

import { editUploadTrackBegin , editUploadTrackSuccess , editUploadTrackFailure } from './ActionCreators'
import { delUploadTrackBegin , delUploadTrackSuccess , delUploadTrackFailure } from './ActionCreators'





import { Base_URL } from '../Configs/BaseUrl'



export const UploadTrackImage = (data) => {

    console.log("_________________tracj__________")
    console.log(data)
    console.log("_________________tracj__________")


    var fdata = new FormData();
    fdata.append('image' , data.image)
    
    return (dispatch) => {
        dispatch(UploadTrackImageLoading());

        axios.put(Base_URL + '/api/v1/admin/uploadImage', fdata)
            .then((res) => {
                console.log(res)
                dispatch(UploadTrackImageSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(UploadTrackImageFailure(err.response.data));

                }
            })
    }
}


export const UploadTrackAudio = (data) => {

    console.log("_________________tracj__________")
    console.log(data)
    console.log("_________________tracj__________")


    var fdata = new FormData();
    fdata.append('podcast' , data.podcast)
    
    return (dispatch) => {
        dispatch(UploadTrackAudioLoading());

        axios.put(Base_URL + '/api/v1/admin/uploadTrack', fdata)
            .then((res) => {
                console.log(res)
                dispatch(UploadTrackAudioSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(UploadTrackAudioFailure(err.response.data));

                }
            })
    }
}



export const addUploadTrack = (data) => {

    console.log("_________________tracj__________")
    console.log(data)
    console.log("_________________tracj__________")


    
   
    return (dispatch) => {
        dispatch(addUploadTrackLoading());

        axios.put(Base_URL + '/api/v1/admin/submitTrack', data)
            .then((res) => {
                console.log(res)
                dispatch(addUploadTrackSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addUploadTrackFailure(err.response.data));

                }
            })
    }
}




export const getUploadTrack = (data) => {
    return (dispatch) => {
        dispatch(getUploadTrackBegin());

        axios.get(Base_URL + '/api/v1/admin/getAllSongs', )
            .then((res) => {
                console.log(res)
                dispatch(getUploadTrackSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")
                }
                else {
                    dispatch(getUploadTrackFailure(err.response.data));
                }
            })
    }
}


export const DelTrack = (data) => {
    console.log(data)



    const newD = {
        'id' : data
    }
    console.log(newD)
    return (dispatch) => {
        dispatch(delUploadTrackBegin());

        axios.post(Base_URL + '/api/v1/admin/deleteSong', newD)
            .then((res) => {
                console.log(res)
                dispatch(delUploadTrackSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(delUploadTrackFailure(err.response.data));

                }
            })
    }
}




export const editTrack = (data) => {



    var fdata = new FormData();
    // fdata.append('aid' , JSON.stringify(data.aid))
    fdata.append('name' , data.name)
    fdata.append('id' , data.id)
    // fdata.append('cid' , data.cid)
    // fdata.append('subid' , JSON.stringify(data.subid))
    // fdata.append('albumid' , data.albumid)
    fdata.append('lyrics' , data.lyrics)
    fdata.append('composer' , data.composer)
    fdata.append('genre' , data.genre)
    fdata.append('year' , '2021')
    fdata.append('multiple_songs' , data.image1)
    fdata.append('multiple_songs' , data.audio1)
   
    return (dispatch) => {
        dispatch(editUploadTrackBegin());
        axios.post(Base_URL + '/api/v1/admin/updateSong', fdata)
            .then((res) => {
                console.log(res)
                dispatch(editUploadTrackSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(editUploadTrackFailure(err.response.data));

                }
            })
    }
}













