import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Configs/Toast'
import {ToastContainer} from 'react-toastify'


import {addCategory} from '../../Actions/cat'
import {cleanCategoryData} from '../../Actions/ActionCreators'
import { Link as RouterLink, useNavigate  } from 'react-router-dom';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Category Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  const handleChangee = (event) => {
    setValues({...values, ['state']:event.target.value});
  };
  const handleChangef = (event) => {
    setValues({...values, ['city']:event.target.value});
  };
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };



//   const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
 
  name : "",


  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleSubmit = () =>{
    if(values.name === "" || values.name === null || values.name === undefined )
    {
      tostW("Please Enter Category Name !")
    }    
    else
    {

    dispatch(addCategory(values))
    navigate("/app/categorylist")
  }

  }


  return (
    <Page
      className={classes.root}
      title="Add Category"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Category" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {/* <Grid item md={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  style={{ border: "1px  black" }}
                  id="date-picker-dialog"
                  label="Added Date"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  variant="outlined"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  fullWidth
                  margin="normal"
                  id="time-picker"
                  label="Added Time"
                  value={selectedDate}
                  onChange={handleTimeChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Category Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Business/व्यवसाय/सेवा"
                name="business"
                onChange={handleChange}
                required
                value={values.business}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Designation/कार्य का विवरण"
                name="designation"
                onChange={handleChange}
                required
                value={values.designation}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Office Address/कार्यालय पता"
                name="office_address"
                onChange={handleChange}
                required
                value={values.office_address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile Number/मोबाइल नंबर"
                name="mobile_number"
                onChange={handleChange}
                required
                value={values.mobile_number}
                variant="outlined"
              />
            </Grid> */}

            {/* <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Type Of Business</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.type_of_business}
          onChange={handleChanget}
          label="Type Of Business"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value='Kirana'>Kirana</MenuItem>
          <MenuItem value='Hardware'>Hardware</MenuItem>
          <MenuItem value='Electronics'>Electronics</MenuItem>
          <MenuItem value='Footwear'>Footwear</MenuItem>
          <MenuItem value='Jewellery'>Jewellery</MenuItem>
          <MenuItem value='Animals Food'>Animals Food</MenuItem>
          <MenuItem value='General Store'>General Store</MenuItem>
          <MenuItem value='Bakery'>Bakery</MenuItem>
          <MenuItem value='Dairy Booth'>Dairy Booth</MenuItem>
          <MenuItem value='DJ Sound'>DJ Sound</MenuItem>
          <MenuItem value='Manure And Seeds'>Manure And Seeds</MenuItem>
          <MenuItem value='Tant House'>Tant House</MenuItem>
          <MenuItem value='Beauty Parlour'>Beauty Parlour</MenuItem>
          <MenuItem value='Stationary'>Stationary</MenuItem>
          <MenuItem value='Paan Bhandar'>Paan Bhandar</MenuItem>
          <MenuItem value='Redimate Garments'>Redimate Garments</MenuItem>


        </Select>
      </FormControl>
      </Grid>
            
            
            <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.country}
          onChange={handleChanged}
          label="Country"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {CData.map((data , i)=>
          <MenuItem key={i} onClick={()=>handleCountryMenu(data.c_id)} value={data.name}>{data.name}/{data.native}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.state}
          onChange={handleChangee}
          label="State"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {SData.map((data , i)=>
          <MenuItem key={i} onClick={()=>handleStateMenu(data.s_id)} value={data.name}>{data.name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.city}
          onChange={handleChangef}
          label="City"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          
          {CiData.map((data , i)=>
          <MenuItem key={i}  value={data.name}>{data.name}</MenuItem>
          )}        

        </Select>
      </FormControl>
      </Grid> */}

      
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
