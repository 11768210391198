import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Configs/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';
import ReactAudioPlayer from 'react-audio-player';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Link as RouterLink, Navigate, useNavigate , useLocation  } from "react-router-dom";



import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import {getCountryData , getStateData , getCityData} from '../../Actions/StateCity'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import {addUploadTrack , editTrack, getUploadTrack} from '../../Actions/UploadTrack'
import {cleanUploadTrackData} from '../../Actions/ActionCreators'

const user = {
  avatar: '/static/music.jpg',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Track Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();




  const [personName, setPersonName] = React.useState([]);


  const handlepersonChange = (event) => {
    console.log(event)
  setPersonName(event.target.value);

};




const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };



const CData = useSelector((state) =>
  state.Category.getdata &&
    state.Category.getdata.length &&
    Array.isArray(state.Category.getdata)
      ? state.Category.getdata
      : []
  );


  const AlbumData = useSelector((state) =>
  state.Album.getdata &&
    state.Album.getdata.length &&
    Array.isArray(state.Album.getdata)
      ? state.Album.getdata
      : []
  );


  const SubcatData = useSelector((state) =>
  state.SubCategory.getdata &&
    state.SubCategory.getdata.length &&
    Array.isArray(state.SubCategory.getdata)
      ? state.SubCategory.getdata
      : []
  );





  const [values, setValues] = useState({
   
  image1:"",
  tempImage1:state.thumbnail,
  name : state.name,
  tempAudio1:state.url,
  audio1 :"",
  cid:state.cid,
  cname:"",
  albumid:'',
  albumname:"",
  aid:'',
  subid:'',
  subname:'',
  genre:state.genre,
  composer:state.composer,
  tempSubArray: [],
  lyrics:state.lyrics,
  mainartist :'',
  mainartistId :[],



  fixImg : "/static/music.jpg"

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const onFileChange = event => { 
    
    // console.log(event.target.files[0])
    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['audio1']:fileUploaded , ['tempAudio1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }
   
  }; 


  



  

 



  const handleSubmit = () =>{
    var t_cid =[];
    var t_subid=[];
    var t_albumid=[];


    t_cid.push(parseInt( values.cid))
    t_subid.push(parseInt(values.subid))
    t_albumid.push(parseInt(values.albumid))


  

  var temp = [];

    //   let x = new Promise((resolve,reject)=>{
    //     temp.push(values.mainartistId[0])

    //     for(let v = 0 ; v < personName.length ; v++)
    //     {


    //       for(var w  = 0 ; w < artistData.length ; w++ )
    //       {
    //         if (artistData[w].name === personName[v])
    //         {
    //           temp.push(artistData[w].id)
    //         }
    //       }
          
    //     }


    //     resolve()
    //   })
    //   x.then(()=>{
    //     if(temp.length === 0)
    //     {
    //       alert("Please Select Artist Name")
    //     }
    //     else
    //     {
          var ndata = {
            image1:values.image1,
            tempImage1:values.tempImage1,
            name :values.name,
            aid : temp,
            audio1: values.audio1,
            tempAudio1:values.tempAudio1,
            albumid:values.albumid,
            subid:values.subid,
            cid:values.cid,
            composer:values.composer,
            lyrics:values.lyrics,
            genre:values.genre,
            id:state.id,
            year:""
          }

          console.log(ndata)
      
    dispatch(editTrack(ndata))
    
        // }

    //   })


  }


  const handleChangesubcat = (x , y) => {
    setValues({...values, ['subid']:[x] , ['subname'] : y});
  };
  const handleChangecat = (x , y) => {
    setValues({...values, ['cid']:x , ['cname'] : y})
  };

  const handleChangeAlbum = (x , y) => {
    setValues({...values, ['albumid']:x , ['albumname'] : y});
  };




  const artistData = useSelector((state) =>
  state.Artist.getdata &&
    state.Artist.getdata.length &&
    Array.isArray(state.Artist.getdata)
      ? state.Artist.getdata
      : []
  );


  const addmsg = useSelector(state => state.UploadTrack.editdata && state.UploadTrack.editdata.id ? state.UploadTrack.editdata.id : null  )
  const adderror = useSelector(state => state.UploadTrack.editerror && state.UploadTrack.editerror.message ? state.UploadTrack.editerror.message : null  )
  


  useEffect( () => {
       var temp = [];
       console.log("ghjjjjjj")
    // if(values.cid !== "")
    // {
    //      let r = new Promise((resolve , reject)=>{
    //   for(let i = 0 ; i<SubcatData.length ; i++)
    //   {
    //     if(SubcatData[i].cid === values.cid)
    //     {
    //       temp.push(SubcatData[i])
    //     }
    //   }
      
    //   resolve()
    // })
    // r.then(()=>{
      
    //     setValues({...values, ['tempSubArray']:temp});
  
    //   })

    // }


    // const onEdit = (olData) =>{
    //   navigate('/app/editTrack/'+olData.id , { state: olData })
    // }
    

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg && addmsg !== null)
      {
        // tostS("Edit Successfully")
        dispatch(getUploadTrack())
        navigate('/app/tracklist/')

    //     setValues({
    //       ...values,
    //     image1:"",
    //   tempImage1:"",
    //   name : "",
    //   tempAudio1:"",
    //   audio1:'',
    //   cid:'',
    //   albumid:'',
    //   aid:'',
    //   subid:[]
    //     });
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)

      }

      resolve()

    })
    x.then(()=>{
        console.log("test")
              

    dispatch(cleanUploadTrackData())
   

    })

  
     
    }, [addmsg , adderror , values.cid])


  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);

  const [modArtistData , setModData ] = useState([])


  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleClick2 = event => {
    hiddenFileInput2.current.click();
  };
  const handleChangeimg = event => {

    if(event.target.files[0] && event.target.files.length !==0)
    {
      const fileUploaded = event.target.files[0];
      console.log(fileUploaded)
      setValues({...values , ['image1']:fileUploaded , ['tempImage1']:URL.createObjectURL(fileUploaded)})
    }
    else
    {
      console.log('yahah')
    }

  };





  
  /********* Image Handling   ************** */


 



  const handleChangesMod =  (x , y) => {
    console.log(x , y)

    let pro  =   new Promise((resolve, reject)=>{

      var orgData  = artistData;
      var newData = [];
      var mainId  = x
      for(let i =0 ; i< orgData.length ; i++)
      {
        if(orgData[i].id !== mainId)
        {
          newData.push(orgData[i])
        }
      }
        setModData(newData)

        resolve()
      })
      pro.then(()=>{
        
        setValues({...values, ['mainartist']:y , ['mainartistId']  : [x]});

    })


  };

  return (
    <Page
      className={classes.root}
      title="Add Track"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {/* <Profile /> */}
            <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={values.tempImage1 !== '' ?  values.tempImage1 : values.fixImg }
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>

          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${user.timezone}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeimg}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick}
        >
          Upload picture
        </Button>
      </CardActions>
    </Card>
            {/* <Profile /> */}
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >


      
      <Card>
        <CardHeader subheader="" title="Edit Song" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>

                      <Grid item md={6} xs={12} style={{justifyContent:"center"}}> 
 

          <Card
      className={clsx(classes.root2, className)}
      {...rest}
    >
 
      <Divider />
      {/* <CardActions>
      <input
        type="file"
        ref={hiddenFileInput2}
        onChange={onFileChange}
        style={{display: 'none'}} 
      />
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleClick2}
        >
          Upload Song
        </Button>
      </CardActions> */}
    </Card>
    </Grid>

          {/* <Grid item md={6} xs={12} style={{justifyContent:"center"}}>
              <div style={{justifyContent:"center"}}> 
                <div style={{marginTop:4,justifyContent:"center" }}> 
                    <input type="file" onChange={onFileChange} /> 
                </div> 
              </div> 
            </Grid> */}

           

            {/* <Grid item md={6} xs={12} style={{justifyContent:"center"}} >
             {values.tempAudio1 !== "" ?             
                <ReactAudioPlayer
                  src={values.tempAudio1}
                  autoPlay={false}
                  controls
                />
                :
                <ReactAudioPlayer
                  src={values.tempAudio1}
                  autoPlay={false}
                  controls
                />
                }    
            </Grid> */}
           
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Song Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Composer"
                name="composer"
                onChange={handleChange}
                required
                value={values.composer}
                variant="outlined"
              />
            </Grid>


           
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Genre"
                name="genre"
                onChange={handleChange}
                required
                value={values.genre}
                variant="outlined"
              />
            </Grid>
            

            <Grid item md={12} xs={12}>
            <TextareaAutosize value={values.lyrics} variant="outlined" name='lyrics' onChange={handleChange}  style={{width:"100%" , textAlign:"center" , borderColor:"gray" }}  aria-label="minimum height" rowsMin={3} placeholder="Song Lyrics" />              
            </Grid>
            {/* <Grid item md={6} xs={12} style={{justifyContent:"center"}} >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date picker inline"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    </Grid>
        </MuiPickersUtilsProvider>
        </Grid> */}



            {/* <Grid item md={12} xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Category</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.cname}
                // onChange={handleChangeCatValues}
                label="Category"
                
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {CData.map((data , i)=>
                <MenuItem  key={i} onClick={()=>handleChangecat(data.id , data.name)} value={data.name}>{data.name}</MenuItem>
                )}    
                </Select>
                </FormControl>
                </Grid>


                <Grid item md={12} xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Sub-Category</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.subname}
                // onChange={handleChangesubcat}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {values.tempSubArray.map((data , i)=>
                <MenuItem key={i} onClick={()=>handleChangesubcat(data.id , data.name)} value={data.name}>{data.name}</MenuItem>
                )}    
                </Select>
                </FormControl>
                </Grid>


                <Grid item md={12} xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Album</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.albumname}
                // onChange={handleChangesubcat}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {AlbumData.map((data , i)=>
                <MenuItem key={i} onClick={()=>handleChangeAlbum(data.id , data.name)} value={data.name}>{data.name}</MenuItem>
                )}    
                </Select>
                </FormControl>
                </Grid>
             */}


                {/* <Grid item md={12} xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Select Main Artist</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.mainartist}
                // onChange={handleChangesubcat}
                label="Main Artist"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {artistData.map((data , i)=>
                <MenuItem key={i} onClick={()=>handleChangesMod(data.id , data.name)} value={data.name}>{data.name}</MenuItem>
                )}    
                </Select>
                </FormControl>
                </Grid>
            

 */}

            
                {/* {modArtistData && modArtistData.length > 0 && Array.isArray(modArtistData) ? 


            <Grid item md={12} xs={12}>
                 <FormControl style={{borderWidth:1 , borderTopWidth:2}} variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Select Artists</InputLabel>
        <Select
        style={{borderWidth:1}}
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={personName}
          onChange={handlepersonChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {modArtistData.map((data ) => (
            <MenuItem  key={data.id} value={data.name}>
              <Checkbox checked={personName.indexOf(data.name) > -1} />
              <ListItemText primary={data.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
     

                 </Grid>
                  : <> </> } */}

               
        
            
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
