import React ,  {useRef , useState , useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';
import {tostS  , tostW , tostE } from '../../Configs/Toast'
import {ToastContainer} from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch  from '@material-ui/core/Switch'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Link as RouterLink, Navigate, useNavigate , useLocation  } from "react-router-dom";


// import {getCountryData , getStateData , getCityData} from '../../Actions/StateCity'


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import {editCategory} from '../../Actions/cat'
import {cleanCategoryData} from '../../Actions/ActionCreators'

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Upload Category Image',
  timezone: moment(Date.now()).format('DD/MM/YY')
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  root2: {},
  avatar: {
    height: 250,
    width: 250
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const AddBusiness = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const navigate = useNavigate();



  const handleChanged = (event) => {
    setValues({...values, ['country']:event.target.value});
  };
  const handleChangee = (event) => {
    setValues({...values, ['state']:event.target.value});
  };
  const handleChangef = (event) => {
    setValues({...values, ['city']:event.target.value});
  };
  const handleChanget = (event) => {
    setValues({...values, ['type_of_business']:event.target.value});
  };



//   const id = useSelector(state => state.login.data.id);
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));


  const [values, setValues] = useState({
 
  name :state.name,
  id:state.id,

  });


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

 
  const handleSubmit = () =>{
    if(values.name === "" || values.name === null || values.name === undefined )
    {
      tostW("Please Enter Artist Name !")
    }    
    else
    {
      console.log(values)
    dispatch(editCategory(values))
    
  }

  }




  const addmsg = useSelector(state => state.Category.editdata && state.Category.editdata.status ? state.Category.editdata.status : null  )
  const adderror = useSelector(state => state.Category.editerror && state.Category.editerror.message ? state.Category.editerror.message : null  )


  useEffect( () => {

    let x  = new Promise((resolve ,reject)=>{
      if(addmsg && addmsg!==null)
      {
        tostS("Edited Successfully")
        navigate('/app/categorylist/')
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }

      resolve()

    })
    x.then(()=>{

    dispatch(cleanCategoryData())
   

    })

  
     
    }, [addmsg , adderror])


  return (
    <Page
      className={classes.root}
      title="Add Artist"
    >
              <ToastContainer    />

      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            {/* <ProfileDetails /> */}
            <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="" title="Add Artist" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
           
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Category Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            
          
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            {/* <ProfileDetails /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddBusiness;
