import {ADD_CATEGORY_LOADING , ADD_CATEGORY_SUCCESS , ADD_CATEGORY_FAILURE} from '../Actions/Types';
import {GET_CATEGORY_LOADING , GET_CATEGORY_SUCCESS , GET_CATEGORY_FAILURE} from '../Actions/Types';
import {EDIT_CATEGORY_LOADING , EDIT_CATEGORY_SUCCESS , EDIT_CATEGORY_FAILURE} from '../Actions/Types';
import {DEL_CATEGORY_LOADING , DEL_CATEGORY_SUCCESS , DEL_CATEGORY_FAILURE} from '../Actions/Types';
import {CLEAN_CATEGORY_DATA} from '../Actions/Types';




function rmOrder(allOrders, index) {
    if (Array.isArray(allOrders)) {
      let newOrders = allOrders;
      newOrders.splice(index, 1);
      return newOrders;
    } else {
      return allOrders;
    }
  }


const Category = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},


        }
    }




switch(action.type) {
    // case CLEAN_ALL_DATA:
    //     return {
    //         ...state,
    //         data: [{}],
    //         loading:false,
    //         error:{},
    //         getdata: [{}],
    //         geterror: {},
    //         editdata: [{}],
    //         editerror: {},
    //         deldata: [{}],
    //         delerror: {},
            
    //     }

    case CLEAN_CATEGORY_DATA:
        return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                // getdata: [{}],
                geterror: {},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                
            }
    
    case ADD_CATEGORY_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case ADD_CATEGORY_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_CATEGORY_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

        case GET_CATEGORY_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_CATEGORY_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_CATEGORY_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };


            case DEL_CATEGORY_LOADING:
              return {
                ...state,
                loading: true,
                delerror: {},
              };
            case DEL_CATEGORY_SUCCESS:
              return {
                ...state,
                loading: false,
                delerror: {},
                deldata: action.deldata,
                getdata: rmOrder(state.getdata, action.index),
              };
            case DEL_CATEGORY_FAILURE:
              return {
                ...state,
                loading: false,
                delerror: action.delerror,
                delData: {},
              };
        
            case EDIT_CATEGORY_LOADING:
              return {
                ...state,
                loading: true,
                editerror: {},
              };
            case EDIT_CATEGORY_SUCCESS:
              return {
                ...state,
                loading: false,
                editerror: {},
                editdata: action.editdata,
              };
            case EDIT_CATEGORY_FAILURE:
              return {
                ...state,
                loading: false,
                editerror: action.editerror,
                editdata: {},
              };    







            
     

    default:
        return state






}
}



export default Category;
