import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';

import DashboardView from 'src/views/reports/DashboardView';

import AddCategory from 'src/views/Category/AddCat'
import ListCategory from 'src/views/Category/ListCat'
import EditCat from 'src/views/Category/EditCat'


import AddTrack from 'src/views/UploadTrack/AddTrack'
import Tracklist from 'src/views/UploadTrack/TrackList'
import EditTrack from 'src/views/UploadTrack/EditTrack'


import SearchSongs from 'src/views/SearchSongs/SearchSongs';
// import TabNav from 'src/views/TabView/TabNav';


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {path: 'dashboard', element: <DashboardView/> },            
      {path:'addcategory' , element:<AddCategory/> },
      {path:'categorylist' , element:<ListCategory/> },
      {path:'editCat/:id' , element:<EditCat/> },      
      {path:'addtrack' , element:<AddTrack/> },
      {path:'tracklist' , element:<Tracklist/> },
      {path:'editTrack/:id' , element:<EditTrack/> },      
      {path:'SearchSongs' , element:<SearchSongs/> },
      // {path:'TabNav' , element:<TabNav/> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  },
  { path: '*', element: <Navigate to="/app/dashboard" /> }
];

export default routes;
