import axios from "axios";

import { userLoginBegin, userLoginSuccess, userLoginFailure } from './ActionCreators'
import { Base_URL } from '../Configs/BaseUrl'






export const login = (session) => {

    console.log(session)
    return (dispatch) => {
        dispatch(userLoginBegin());

        axios.put(Base_URL + '/api/v1/admin/login', session )
            .then((res) => {
                console.log(res)

                
                var userData  = {
                    token  : res.headers.authorization
                }
                localStorage.setItem('x-auth', res.headers.authorization);
                dispatch(userLoginSuccess(userData));
            }).catch((err) => {
                if (!err.response) {
                    alert("Something Went wrong : Please Check Network or Server Connectivity")
                }
                else {
                    dispatch(userLoginFailure(err.response.data));

                }
            })
    }
}





