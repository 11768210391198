import {GET_DASH_LOADING , GET_DASH_SUCCESS , GET_DASH_FAILURE} from '../Actions/Types';





function rmOrder(allOrders, index) {
    if (Array.isArray(allOrders)) {
      let newOrders = allOrders;
      newOrders.splice(index, 1);
      return newOrders;
    } else {
      return allOrders;
    }
  }


const Dashboard = (state , action) => {
    if(typeof state === 'undefined') {
        return {
           
            loading:false,
            getdata: [{}],
            geterror: {},
           
        }
    }




switch(action.type) {


   
 

        case GET_DASH_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_DASH_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_DASH_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };


         

    default:
        return state






}
}



export default Dashboard;
