



export const TEST_JOB_LOADING = "TEST_JOB_LOADING";
export const TEST_JOB_SUCCESS = 'TEST_JOB_SUCCESS';
export const TEST_JOB_FAILURE = 'TEST_JOB_FAILURE';


// Refresh Token


export const REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING";
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

// Login Types

export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';


// All Users


export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING";
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const EDIT_USERS_LOADING = "EDIT_USERS_LOADING";
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_FAILURE = 'EDIT_USERS_FAILURE';



export const DEL_USERS_LOADING = "DEL_USERS_LOADING";
export const DEL_USERS_SUCCESS = 'DEL_USERS_SUCCESS';
export const DEL_USERS_FAILURE = 'DEL_USERS_FAILURE';

export const CLEAN_USERS_DATA = 'CLEAN_USERS_DATA';





// Category Types


export const ADD_CATEGORY_LOADING = "ADD_CATEGORY_LOADING";
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const EDIT_CATEGORY_LOADING = "EDIT_CATEGORY_LOADING";
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';

export const DEL_CATEGORY_LOADING = "DEL_CATEGORY_LOADING";
export const DEL_CATEGORY_SUCCESS = 'DEL_CATEGORY_SUCCESS';
export const DEL_CATEGORY_FAILURE = 'DEL_CATEGORY_FAILURE';


export const DEL_NOTIFICATION_LOADING = "DEL_NOTIFICATION_LOADING";
export const DEL_NOTIFICATION_SUCCESS = 'DEL_NOTIFICATION_SUCCESS';
export const DEL_NOTIFICATION_FAILURE = 'DEL_NOTIFICATION_FAILURE';


export const CLEAN_CATEGORY_DATA = 'CLEAN_CATEGORY_DATA';


// Upload Track Types


export const UPLOAD_TRACK_IMAGE_LOADING = "UPLOAD_TRACK_IMAGE_LOADING";
export const UPLOAD_TRACK_IMAGE_SUCCESS = 'UPLOAD_TRACK_IMAGE_SUCCESS';
export const UPLOAD_TRACK_IMAGE_FAILURE = 'UPLOAD_TRACK_IMAGE_FAILURE';
export const CLEAN_TRACK_IMAGE_DATA = 'CLEAN_TRACK_IMAGE_FAILURE';


export const UPLOAD_TRACK_AUDIO_LOADING = "UPLOAD_TRACK_AUDIO_LOADING";
export const UPLOAD_TRACK_AUDIO_SUCCESS = 'UPLOAD_TRACK_AUDIO_SUCCESS';
export const UPLOAD_TRACK_AUDIO_FAILURE = 'UPLOAD_TRACK_AUDIO_FAILURE';
export const CLEAN_TRACK_AUDIO_DATA = 'CLEAN_TRACK_AUDIO_FAILURE';

export const ADD_UPLOAD_TRACK_LOADING = "ADD_UPLOAD_TRACK_LOADING";
export const ADD_UPLOAD_TRACK_SUCCESS = 'ADD_UPLOAD_TRACK_SUCCESS';
export const ADD_UPLOAD_TRACK_FAILURE = 'ADD_UPLOAD_TRACK_FAILURE';

export const GET_UPLOAD_TRACK_LOADING = "GET_UPLOAD_TRACK_LOADING";
export const GET_UPLOAD_TRACK_SUCCESS = 'GET_UPLOAD_TRACK_SUCCESS';
export const GET_UPLOAD_TRACK_FAILURE = 'GET_UPLOAD_TRACK_FAILURE';

export const EDIT_UPLOAD_TRACK_LOADING = "EDIT_UPLOAD_TRACK_LOADING";
export const EDIT_UPLOAD_TRACK_SUCCESS = 'EDIT_UPLOAD_TRACK_SUCCESS';
export const EDIT_UPLOAD_TRACK_FAILURE = 'EDIT_UPLOAD_TRACK_FAILURE';

export const DEL_UPLOAD_TRACK_LOADING = "DEL_UPLOAD_TRACK_LOADING";
export const DEL_UPLOAD_TRACK_SUCCESS = 'DEL_UPLOAD_TRACK_SUCCESS';
export const DEL_UPLOAD_TRACK_FAILURE = 'DEL_UPLOAD_TRACK_FAILURE';


export const CLEAN_UPLOAD_TRACK_DATA = 'CLEAN_UPLOAD_TRACK_DATA';



export const LOGOUT_ADMIN = 'LOGOUT_ADMIN';


export const GET_DASH_LOADING = "GET_DASH_LOADING";
export const GET_DASH_SUCCESS = 'GET_DASH_SUCCESS';
export const GET_DASH_FAILURE = 'GET_DASH_FAILURE';






















