import {REFRESH_TOKEN_LOADING , REFRESH_TOKEN_SUCCESS , REFRESH_TOKEN_FAILURE} from '../Actions/Types';

const Auth = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},

        }
    }




switch(action.type) {
    

        case REFRESH_TOKEN_LOADING:
            return {
               ...state,
               loading:true,
                error: {}
            }
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data: {...state.data, token: action.token}
            }
        case REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error
            }


    default:
        return state






}
}



export default Auth;
