import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Configs/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
// import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import {getNotifications} from '../../Actions/Notifications'
// import {getCountry , getStates , getCities} from '../../Actions/StateCity'
// import {getSongCountry, getSongCities,getSongStates } from '../../Actions/SearchSongs'
// import {cleanNotificationData , cleanSongSearchData } from '../../Actions/ActionCreators'
import { Link as RouterLink, useNavigate , useLocation  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from '@material-ui/core/Select';


import tableIcons from '../../Configs/IconsFile'


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  root: {
    width:"100%",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    width:"80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));




const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch()
  const {state} = useLocation();
  const navigate = useNavigate();
  const bull = <span className={classes.bullet}>•</span>;

  const [values, setValues] = React.useState({
    selected_country : "",
    selected_state : "",
    selected_city : "",
  });

  const [flags , setflags ] = useState({
    ctflag:false,
    sflag:false,
    ciflag:false,
})


  const handleChangeSelect1 = (event) => {
      console.log(event.target.value)
      let g = new Promise((resolve  , reject)=>{
          setValues({...values, ['selected_country'] :event.target.value  , ['selected_state'] :""  ,['selected_city'] :""  });

      resolve()  
      })
      g.then(()=>{
        // if(event.target.value !== undefined || event.target.value !== null || event.target.value !== "")
        // {   
            // dispatch(getSongCountry(event.target.value.name))
            // dispatch(getStates(event.target.value.isoCode))
            setflags({...flags , ['sflag'] : true})
        // }
      })


  };


  const handleChangeSelect2 = (event) => {
    console.log(event.target.value.name)
    console.log(event.target.value)
    let r = new Promise((resolve, reject)=>{
      setValues({...values, ['selected_state'] :event.target.value, ['selected_city'] :"" });
    resolve()
    })
    r.then(()=>{

      if(event.target.value !== undefined || event.target.value !== null || event.target.value !== "")
    {
        // dispatch(getCities(event.target.value.countryCode , event.target.value.isoCode  ))
        // dispatch(getSongStates(event.target.value.name))
      setflags({...flags , ['ciflag'] : true})
    }

    })
    
};



const handleChangeSelect3 = (event) => {
    console.log(event.target.value.name)
    setValues({...values, ['selected_city'] :event.target.value});
    if(event.target.values !== undefined || event.target.values !== null || event.target.values !== "")
    {
      //  dispatch(getSongCities(event.target.value.name))
    //   dispatch(getCities(event.target.value  ))
    //   setflags({...flags , ['ciflag'] : true})
    }
};


 
  





  const listData = useSelector((state) =>
  state.SearchSong.ctsongdata &&
    state.SearchSong.ctsongdata.length &&
    Array.isArray(state.SearchSong.ctsongdata)
      ? state.SearchSong.ctsongdata
      : []
  );


  const CountryData = useSelector((state)=>
  
  state.StateCity.ctdata &&
    state.StateCity.ctdata.length &&
    Array.isArray(state.StateCity.ctdata)
      ? state.StateCity.ctdata
      : []
  )


  const StateData = useSelector((state)=>
  
  state.StateCity.sdata &&
    state.StateCity.sdata.length &&
    Array.isArray(state.StateCity.sdata)
      ? state.StateCity.sdata
      : []
  )


  const CityData = useSelector((state)=>
  
  state.StateCity.cidata &&
    state.StateCity.cidata.length &&
    Array.isArray(state.StateCity.cidata)
      ? state.StateCity.cidata
      : []
  )





  
 
  const Delmsg = useSelector(state => state.Notifications.deldata && state.Notifications.deldata.message ? state.Notifications.deldata.message : null  )
  const Delerror = useSelector(state => state.Notifications.delerror && state.Notifications.delerror.message ? state.Notifications.delerror.message : null  )






  

  
useEffect( () => {

//    dispatch(getSongCountry("India"))



    // dispatch(getNotifications())
    // dispatch(cleanSongSearchData())
    // dispatch(getCountry())



    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
      {
        tostS(Delmsg)
      }
      if(Delerror && typeof(Delerror) === 'string')
      {
          tostE(Delerror)
      }

      resolve()

    })
    x.then(()=>{

    // dispatch(cleanSongSearchData())
    // dispatch(getBanners())

    })


    
  }, [  ])


  // const navigate = useNavigate();


  const radioChange = (e) =>{


    console.log(e.target.value)

}

const onEdit = (olData) =>{
  navigate('/app/editBanner/'+olData.id , { state: olData })
}


const onDel = (oldata) =>{
  console.log(oldata)
  console.log(oldata.id)

//   dispatch(DelBanner(oldata.id ,oldata.tableData.id ))
}
 




  


  const addArtistButton = () =>{
    navigate('/app/addBanners')
    
  }

  return (
    <>
      <Page className={classes.root} title="Artists">
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
            <Card className={classes.root}>
      <CardContent>
      <FormControl component="fieldset">
      <FormLabel component="legend">Select Preference</FormLabel>
     
    </FormControl>
    <br></br>
    <br></br>

    <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.selected_country}
          onChange={handleChangeSelect1}
          label="Country"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {CountryData.map((data, i)=>    
          <MenuItem value={data} name={data.name} >{data.name}</MenuItem>          
          )}
          
        </Select>
      </FormControl>

      <br></br>


    {values.selected_country  !== undefined && values.selected_country  !== null && values.selected_country  !== ""  ?

    <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={values.selected_state}
          onChange={handleChangeSelect2}
          label="State"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {StateData.map((data, i)=>    
          <MenuItem value={data}>{data.name}</MenuItem>          
          )}
          
        </Select>
      </FormControl>
      : <></>}

{values.selected_state  !== undefined && values.selected_state  !== null && values.selected_state  !== ""  ?


<FormControl variant="outlined" className={classes.formControl}>
    <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
    <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={values.selected_city}
      onChange={handleChangeSelect3}
      label="City"
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {CityData.map((data, i)=>    
      <MenuItem value={data}>{data.name}</MenuItem>          
      )}
      
    </Select>
  </FormControl>
  : <></>}
  
  
        
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Notifications"
      columns={[
      
      
        {
            title: 'Song Id', field: "songId",editable: "never",
            
          },
      
        {
          title: 'Count ', field: "count",editable: "never",

        },
    

      ]}
      data={listData}
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
