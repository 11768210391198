import React , {useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch , shallowEqual , useSelector} from 'react-redux';

import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import ReactAudioPlayer from 'react-audio-player';
import {ToastContainer} from 'react-toastify'
import {tostS  , tostW , tostE } from '../../Configs/Toast'




// import { testApi } from '../../Actions/Test'

import {login  , logintemp} from '../../Actions/Login'
// import {} from '../../Actions/ActionCreators'
import { Underline } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const phoneRegExp = /^[6-9]\d{9}$/


  const addLogin = useSelector(state => state.Login.data && state.Login.data.token ? state.Login.data.token : null  )
  const Loginerror = useSelector(state => state.Login.error && state.Login.error.message ? state.Login.error.message : null  )




 const onMySubmit = () =>{
   
 }


  useEffect( () => {



    let x  = new Promise((resolve ,reject)=>{
      // if(addLogin && addLogin !== '' || addLogin !== undefined || addLogin !== null  )
      // {
      //   navigate("app/dashboard")
      // }

      if(Loginerror && typeof(Loginerror) === 'string')
      {
        tostE(Loginerror)
        
      }

      resolve()

    })
    x.then(()=>{

    // dispatch(cleanSubCategoryData())
   

    })

  
     
    }, [Loginerror])


    
  const token = useSelector(state => state.Login.data.token)


  if(token || !token === null || !token === undefined || !token === '')
  {
    console.log("test")
    navigate('/dashboard')
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <ToastContainer    />

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              mobile: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
             
              mobile: Yup.string().matches( phoneRegExp ,'Must be a valid Mobile Number').max(255).required('Mobile Number is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={values => {
              dispatch(login(values))
              
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    align="center"
                  >
                    Dravyaguna Admin
                  </Typography>
                  <Typography
                    color="linear-gradient(90deg, hsla(352, 83%, 64%, 1) 0%, hsla(230, 90%, 68%, 1) 100%);"
                    gutterBottom
                    variant="body2"
                    align="center"
                
                  >
                    {/* Sign in Your Platform */}
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  
                </Grid>
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login With Your Credentials.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.mobile && errors.mobile)}
                  fullWidth
                  helperText={touched.mobile && errors.mobile}
                  label="Mobile Number "
                  margin="normal"
                  name="mobile"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.mobile}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
               
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
