import {ADD_UPLOAD_TRACK_LOADING , ADD_UPLOAD_TRACK_SUCCESS , ADD_UPLOAD_TRACK_FAILURE} from '../Actions/Types';
import {GET_UPLOAD_TRACK_LOADING , GET_UPLOAD_TRACK_SUCCESS , GET_UPLOAD_TRACK_FAILURE} from '../Actions/Types';
import {DEL_UPLOAD_TRACK_LOADING , DEL_UPLOAD_TRACK_SUCCESS , DEL_UPLOAD_TRACK_FAILURE} from '../Actions/Types';
import {EDIT_UPLOAD_TRACK_LOADING , EDIT_UPLOAD_TRACK_SUCCESS , EDIT_UPLOAD_TRACK_FAILURE} from '../Actions/Types';
import {CLEAN_UPLOAD_TRACK_DATA } from '../Actions/Types';


import {UPLOAD_TRACK_IMAGE_LOADING,
  UPLOAD_TRACK_IMAGE_SUCCESS,
  UPLOAD_TRACK_IMAGE_FAILURE,
  CLEAN_TRACK_IMAGE_DATA,
  UPLOAD_TRACK_AUDIO_LOADING,
  UPLOAD_TRACK_AUDIO_SUCCESS,
  UPLOAD_TRACK_AUDIO_FAILURE,
  CLEAN_TRACK_AUDIO_DATA} from '../Actions/Types'


function rmOrder(allOrders, index) {
    if (Array.isArray(allOrders)) {
      let newOrders = allOrders;
      newOrders.splice(index, 1);
      return newOrders;
    } else {
      return allOrders;
    }
  }


const UploadTrack = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            data: [{}],
            loading:false,
            error:{},
            getdata: [{}],
            geterror: {},
            editdata: [{}],
            editerror: {},
            deldata: [{}],
            delerror: {},
            
            imgdata: [{}],
            imgerror: {},
            
            audData: [{}],
            audError: {},
            


        }
    }




switch(action.type) {
    // case CLEAN_ALL_DATA:
    //     return {
    //         ...state,
    //         data: [{}],
    //         loading:false,
    //         error:{},
    //         getdata: [{}],
    //         geterror: {},
    //         editdata: [{}],
    //         editerror: {},
    //         deldata: [{}],
    //         delerror: {},
            
    //     }

    case CLEAN_UPLOAD_TRACK_DATA:
        return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                editdata: [{}],
                editerror: {},
                deldata: [{}],
                delerror: {},
                
            }

    case CLEAN_TRACK_IMAGE_DATA:
        return {
                ...state,
                loading:false,
                imgdata: [{}],
                imgerror: {}
                
            }

    case CLEAN_TRACK_AUDIO_DATA:
        return {
                ...state,
                loading:false,
                audData: [{}],
                audError: {}
                
            }


      
    
    case UPLOAD_TRACK_IMAGE_LOADING:
        return {
            ...state,
            loading:true,
            imgdata: [{}],
            imgerror: {},
            
        }
    case UPLOAD_TRACK_IMAGE_SUCCESS:
        return {
            ...state,
            loading:false,
            imgerror:{},
            imgdata:action.data
        }
    case UPLOAD_TRACK_IMAGE_FAILURE:
        return {
            ...state,
            loading:false,
            imgerror:action.error,
            imgdata:{},
        }

      
      
    
    case UPLOAD_TRACK_AUDIO_LOADING:
        return {
            ...state,
            loading:true,
            auddata: [{}],
            auderror: {},
            
        }
    case UPLOAD_TRACK_AUDIO_SUCCESS:
        return {
            ...state,
            loading:false,
            auderror:{},
            auddata:action.data
        }
    case UPLOAD_TRACK_AUDIO_FAILURE:
        return {
            ...state,
            loading:false,
            auderror:action.error,
            auddata:{},
        }

      
    
    case ADD_UPLOAD_TRACK_LOADING:
        return {
            ...state,
            loading:true,
            error: {},
            
        }
    case ADD_UPLOAD_TRACK_SUCCESS:
        return {
            ...state,
            loading:false,
            error:{},
            data:action.data
        }
    case ADD_UPLOAD_TRACK_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.error,
            data:{},
        }

        case GET_UPLOAD_TRACK_LOADING:
            return {
              ...state,
              loading: true,
              geterror: {},
            };
          case GET_UPLOAD_TRACK_SUCCESS:
            return {
              ...state,
              loading: false,
              geterror: {},
              getdata: action.getdata,
            };
          case GET_UPLOAD_TRACK_FAILURE:
            return {
              ...state,
              loading: false,
              geterror: action.geterror,
              getdata: {},
            };


            case DEL_UPLOAD_TRACK_LOADING:
              return {
                ...state,
                loading: true,
                delerror: {},
              };
            case DEL_UPLOAD_TRACK_SUCCESS:
              return {
                ...state,
                loading: false,
                delerror: {},
                deldata: action.deldata,
                getdata: rmOrder(state.getdata, action.index),
              };
            case DEL_UPLOAD_TRACK_FAILURE:
              return {
                ...state,
                loading: false,
                delerror: action.delerror,
                delData: {},
              };
        
            case EDIT_UPLOAD_TRACK_LOADING:
              return {
                ...state,
                loading: true,
                editerror: {},
              };
            case EDIT_UPLOAD_TRACK_SUCCESS:
              return {
                ...state,
                loading: false,
                editerror: {},
                editdata: action.editdata,
              };
            case EDIT_UPLOAD_TRACK_FAILURE:
              return {
                ...state,
                loading: false,
                editerror: action.editerror,
                editdata: {},
              };  


            
     

    default:
        return state






}
}



export default UploadTrack;
