import 'date-fns';
import React, { useRef, useState, useEffect } from "react";
import Page from "../../../src/components/Page";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { tostS, tostW, tostE } from "../../Configs/Toast";
import clsx from "clsx";
import { connect } from "react-redux";
import ReactAudioPlayer from 'react-audio-player';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import axios from '../../Configs/Interceptor'
import {Base_URL} from '../../Configs/BaseUrl'

import {getUploadTrack, DelTrack } from '../../Actions/UploadTrack'
// import {getArtists} from '../../Actions/Artist'
import {cleanUploadTrackData } from '../../Actions/ActionCreators'
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';

import tableIcons from '../../Configs/IconsFile'


import {AddBox , ArrowDownward} from '@material-ui/icons'




import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  makeStyles,
  Container,
  Grid,
  CardHeader,
  TextField,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  root2: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid green',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  rootU: {
    flexGrow: 1,
  },
  paperU: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height:100,
    justifyContent:"center",
    color: theme.palette.text.secondary,
  },
}));

const ListView = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch =  useDispatch();
  const tableRef = React.createRef();


  const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));

  const [open, setOpen] = React.useState(false);
  const [modalData  , setModalData] = React.useState([])

  
  
  const handleOpen = (data) => {
    setOpen(true);
    setModalData(data)

  };

  const handleClose = () => {
    setOpen(false);
  };
  


  const addmsg = useSelector(state => state.UploadTrack.data && state.UploadTrack.data.status ? state.UploadTrack.data.status : null  )
  const adderror = useSelector(state => state.UploadTrack.error && state.UploadTrack.error.message ? state.UploadTrack.error.message : null  )

  const Delmsg = useSelector(state => state.UploadTrack.deldata && state.UploadTrack.deldata.message ? state.UploadTrack.deldata.message : null  )
  const Delerror = useSelector(state => state.UploadTrack.delerror && state.UploadTrack.delerror.message ? state.UploadTrack.delerror.message : null  )
  const Scerror = useSelector(state => state.UploadTrack.scerror && state.UploadTrack.scerror.message ? state.UploadTrack.scerror.message : null  )
  // const count = useSelector(state => state.UploadTrack.scdata && Array.isArray(state.UploadTrack.scdata) && state.UploadTrack.scdata[0] && state.UploadTrack.scdata[0].count ? state.UploadTrack.scdata[0].count : null  )

  
  useEffect( () => {
    dispatch(getUploadTrack())
    let x  = new Promise((resolve ,reject)=>{
      if(Delmsg && typeof(Delmsg) === 'string')
        {
          tostS(Delmsg)
          
        }
      if(Delerror && typeof(Delerror) === 'string')
        {
            tostE(Delerror)
        }
      if(Scerror && typeof(Scerror) === 'string')
        {
            tostE(Scerror)
        }      
      if(addmsg && addmsg === '200')
      {
        tostS("Added Successfully")
      }
      if(adderror && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }

      resolve()
    })
    x.then(()=>{
    // dispatch(cleanSongCount())
    // window.location.reload()
    dispatch(getUploadTrack())
    dispatch(cleanUploadTrackData())
    }).catch(()=>{
      dispatch(getUploadTrack())
      dispatch(cleanUploadTrackData())
    })
  
  
    
  }, [Delmsg , Delerror , Scerror, addmsg, adderror])


  function rmOrder(allOrders ) {
    if (Array.isArray(allOrders) && allOrders.length) {
      let temp =  allOrders ;

      for(let i = 0  ; i  <  temp.length ;  i ++)
      {
        console.log("*******************" , temp)
        axios.get(`${Base_URL}/api/v1/admin/getSignedUrlAdmin` , {params : {fileName  :temp[i].url.split('/').slice(-1)[0]}}).then((fl)=>{
           
          temp[i]['sign_url'] = fl.data

        }).catch((fr)=>{
          
          temp[i]['sign_url'] = ""
        })
      }
      
      return temp;
    } else {
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      return allOrders;
    }
  }




  const listData = useSelector((state) =>
  state.UploadTrack.getdata.results &&
    state.UploadTrack.getdata.results.length &&
    Array.isArray(state.UploadTrack.getdata.results)
      ? state.UploadTrack.getdata.results
      : []
  );

  console.log(listData)


  const [newData , setNewData] = React.useState(rmOrder(listData))


  



 
  


 


  const navigate = useNavigate();

const onEdit = (olData) =>{
  navigate('/app/editTrack/'+olData.id,{state:olData})
}


const onDel = (oldata) =>{
  dispatch(DelTrack(oldata.id))
}


const onSongCount = (oldata) =>{
  // dispatch(getSongCount(oldata.id  ))
}
 

const handleDateChange = (date) => { 
  setSelectedDate(date);
  var y  = moment(date).format("DD/MM/YYYY")
  console.log(y)

var temp = [];

var c =  new Promise((resolve , reject)=>{

  for(let i  = 0 ; i< listData.length ; i++)
  {
    var u = moment(listData[i].createdAt).format("DD/MM/YYYY")
    if(y === u)
    {
      temp.push(listData[i])
    }
  }
resolve()
})

c.then(()=>{

  setNewData(temp)
})



};


  async function getUrlAudio(data){
    await axios.get(`${Base_URL}/api/v1/admin/getSignedUrlAdmin` , {params : {fileName  :data.url.split('/').slice(-1)[0]}}).then((fl)=>{
      console.log(fl.data)
      return  (fl.data)
    }).catch((fr)=>{
      console.log(fr)
      return ""
    })
  }  


  const addAlbumButton = () =>{
    navigate('/app/addtrack')
  }

  return (
    <>
      <Page className={classes.root} title="Vargas">
      <ToastContainer    />
        <Container maxWidth={false}>
          {/* <Toolbar /> */}
          <div className={clsx(classes.root2, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
              {/* <Button className={classes.importButton}>Import</Button>
              <Button className={classes.exportButton}>Export</Button> */}
              <Button onClick={addAlbumButton} style={{marginTop:20}} color="primary" variant="contained">
                Add Track
              </Button>
              
  


            </Box>
           
          </div>
          <Box mt={3}>
            <Card className={clsx(classes.root2, className)} {...rest}>

            <MaterialTable
      icons={tableIcons}      
      title="Added Vargas"
      columns={[
        {
          title: 'Images',
          field: 'images',
          editable: "never",
          render: rowData => (
        
        <img src={rowData.thumbnail} style={{width:'100px' , height:'100px' , borderRadius:10}}/>
          ),
        },
        {
          title: 'Added Date', field: "createdAt",editable: "never",
          render: rowData => (
            moment(rowData.createdAt).format("DD/MM/YYYY")
          ),
        },
        { title: 'Name', field: 'name',editable: "never", },
                 
        {
          title: 'Audio', field: "audio",editable: "never",
          render: rowData => (
            <ReactAudioPlayer
            src={rowData.sign_url}
            autoPlay={false}
            // onPlay={()=>{alert("hello")}}
            controls
            />
            ),
          },
              
        {
          title: 'Action',
          render: rowData => (            
                <DeleteIcon onClick={()=>{onDel(rowData)}} style={{cursor:"pointer"}} />             
          ),
        },

      ]}
      data={newData}    
        options={{
                selection: false,
                textAlign: "center",
                headerStyle: { textAlign: "left" },
                rowStyle: { textAlign: "center" }
              }}

    />
             
            </Card>
          </Box>

          <div>
    
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          backgroundColor:"transparent"
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
          <h4 id="transition-modal-title" style={{color:"red"}} >Song Name</h4>
            <p id="transition-modal-description"><h5> {modalData.name}</h5></p>
          <h4 id="transition-modal-title" style={{color:"red"}} >Created Date</h4>
            <p id="transition-modal-description"><h5> {moment(modalData.createdAt).format("DD/MM/YYYY")}</h5></p>
            <h4 id="transition-modal-title" style={{color:"red"}} >Composer</h4>
            <p id="transition-modal-description"><h5> {modalData.composer ? modalData.composer : "Not Found"}</h5></p>
            <h4 id="transition-modal-title" style={{color:"red"}} >Genre</h4>
            <p id="transition-modal-description"><h5> {modalData.genre ? modalData.genre : "Not Found"}</h5></p>
            <h4 id="transition-modal-title" style={{color:"red"}} >Lyrics</h4>
            <p id="transition-modal-description"><h5> {modalData.lyrics ? modalData.lyrics : "Not Found"}</h5></p>
          </div>
        </Fade>
      </Modal>
    </div>
        </Container>
      </Page>
    </>
  );
};

ListView.propTypes = {
  className: PropTypes.string,
};


export default connect()(ListView);
