import axios from "../Configs/Interceptor";

import {Base_URL} from '../Configs/BaseUrl'


import {refreshTokenBegin , refreshTokenFailure , refreshTokenSuccess} from './ActionCreators'



export const refreshToken = () => {
  return (dispatch) => {
    dispatch(refreshTokenBegin());
    return axios.get( Base_URL + '/api/v1/admin/refreshtoken')
    .then( (res) => {
      console.log(res)
      localStorage.setItem('x-auth', res.headers.authorization);
      dispatch(refreshTokenSuccess(res.headers.authorization));
      return Promise.resolve(res.headers.authorization);
    }).catch( (err) => {
      dispatch(refreshTokenFailure(err.response.data));
      return Promise.reject();
    })
  }
}

